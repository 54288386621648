exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bible-reading-2021-js": () => import("./../../../src/pages/bible-reading-2021.js" /* webpackChunkName: "component---src-pages-bible-reading-2021-js" */),
  "component---src-pages-bible-reading-2022-js": () => import("./../../../src/pages/bible-reading-2022.js" /* webpackChunkName: "component---src-pages-bible-reading-2022-js" */),
  "component---src-pages-bible-reading-2023-js": () => import("./../../../src/pages/bible-reading-2023.js" /* webpackChunkName: "component---src-pages-bible-reading-2023-js" */),
  "component---src-pages-bible-reading-js": () => import("./../../../src/pages/bible-reading.js" /* webpackChunkName: "component---src-pages-bible-reading-js" */),
  "component---src-pages-index-copy-js": () => import("./../../../src/pages/index-copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-th-4-js": () => import("./../../../src/pages/th4.js" /* webpackChunkName: "component---src-pages-th-4-js" */),
  "component---src-pages-the-kings-college-js": () => import("./../../../src/pages/the-kings-college.js" /* webpackChunkName: "component---src-pages-the-kings-college-js" */),
  "component---src-pages-tribes-js": () => import("./../../../src/pages/tribes.js" /* webpackChunkName: "component---src-pages-tribes-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-young-fam-friday-js": () => import("./../../../src/pages/young-fam-friday.js" /* webpackChunkName: "component---src-pages-young-fam-friday-js" */),
  "component---src-pages-young-fam-js": () => import("./../../../src/pages/young-fam.js" /* webpackChunkName: "component---src-pages-young-fam-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

